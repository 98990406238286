@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
body {
    font-size: 16px;
    color: #333;
    font-family: 'Lato', sans-serif;
}
p {
    font-size: 16px;
    color: #333;
}
.container {
    max-width: 1230px;
}
.header-wrap {
    background: transparent;
    padding: 20px 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
}
.header-wrap.fixed {
    background: #fff;
}
.main-logo img {
    max-width: 150px;
}
.main-menu ul {
    margin: 0;
    list-style: none;
}
.main-menu ul li a,
.main-menu ul li button {
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    color: #fff;
    padding: 0 12px;
    background: transparent;
    border: none;
}
.theme-btn {
    background: #09156b;
    border-radius: 30px;
    text-align: center;
    font-size: 17px;
    border: none;
    padding: 14px 40px;
    color: #fff;
    font-weight: 600;
}
.header-wrap .theme-btn {
    padding: 10px 30px;
    font-size: 15px;
}
.mb-overlay {
    background: linear-gradient(90deg,#100727 0%,rgba(16,7,39,.50) 100%);
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.mb-pos {
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;
}
.title-lg {
    font-size: 3em;
    color: #fff;
    line-height: 1.3em;
}
.main-banner-wrap {
    height: 100vh;
    overflow: hidden;
}
.main-banner-wrap video {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.white-box {
    box-shadow: 0 0 30px #cccccc73;
    border: solid 1px #f3f3f3;
    position: relative;
    background: #fff ;
}
.info-wrap .white-box:before {
    content: '';
    background: #09156b;
    height: 50%;
    width: 50%;
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: -1;
    display: none;
}
.title-md {
    font-size: 20px;
    color: #333;
    text-align: center;
    font-weight: 600;
}
.info-wrap p {
    line-height: 22px;
    text-align: center;
}
.mnh-350 {
    min-height: 375px;
}
.info-icon {
    min-height: 100px;
}
.info-icon img {
    max-height: 100px;
}
.services-wrap {
    background: #F7F9FD;
}
.py-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}
.py-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
}
.text-black {
    color: #333 !important;
}
.fw-700 {
    font-weight: 700 !important;
}
.fw-500 {
    font-weight: 500 !important;
}
.mxw-650 {
    max-width: 600px;
    margin: 0 auto;
}

.client-wrap {
    background: #09156B;
    background: radial-gradient(#36414E, #253241);
}
.client-flex .cf-col {
    text-align: center;
    padding: 0 2rem;
}
.footer-wrap {
    background-color: #21212b;
}
.ft-border-r {
    border-right: solid 1px #fff;
}
.city-img img {
    max-width: 100%;
}
.city-img-row-1 img {
    max-width: 70%;
}
.footer-top {
    overflow: hidden;
}
.footer-top p {
    margin-bottom: 0;
}
.footer-top p a {
    text-decoration: none;
    color: #d6d5d5;
}
.footer-logo img {
    max-width: 130px;
}
.footer-top .title-lg {
    font-size: 2.3em;
}
.sm-btn {
    font-size: 14px;
    border: none;
    padding: 13px 32px;
}
.footer-bottom {
    background-color: #1d1d25;
}
.fb-title {
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 18px;
    font-weight: 600;
}
.fb-menu {
    list-style: none;
    padding: 0;
    margin: 0;
}
.fb-menu li {
    color: #8c8c8c;
    font-size: 15px;
    padding: 3px 0;
}
.fb-menu li a {
    color: #8c8c8c;
    padding: 3px 0;
    text-decoration: none;
}
.copyright-block {
    background-color: #15151f;
    padding: 10px 0;
}
.social-links a {
    color: #fff;
    margin-left: 14px;
    text-decoration: none;
    font-size: 24px;
}
.copyright {
    font-size: 14px;
    color: #fff;
}
.overflow-hidden {
    overflow: hidden;
}

.scroll-down.arrow {
    position: absolute;
    left: 50%;
    margin-left: -25px;
    bottom: 55px;
    z-index: 1;
    opacity: 0.5;
    background: transparent;
    border: none;
}
.scroll-down.arrow span {
    position: absolute;
    display: inline-block;
    top: 20px;
    width: 50px;
    height: 50px;
    left: calc(50% - 1px);
    animation: animateArrow 1.5s infinite linear;
    color: #fff;
  }
  /* @keyframes animateArrow {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(40px);
      opacity: 0;
    }
  } */
  .scroll-down.arrow span::before {
    content: "";
    background: url(../img/down-arrow.png) no-repeat center center;
    background-size: 100%;
    width: 50%;    
    height: 50%;    
    display: inline-block;
  }
  body::-webkit-scrollbar {
    width: 6px;
  }
  body::-webkit-scrollbar-track {
    background: #ccc;
  }
  body::-webkit-scrollbar-thumb {
    background-color: #333;
  }
  .mobile-inner-header-icon {
    display: none;
  }
  .gradient-btn {
    text-shadow: rgba(0, 0, 0, 0.2) 0 -1px 0;
    border: 0px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 2px, inset rgba(255, 255, 255, 0.88) 0px 1px 3px -1px;
    background-color: #4589E3;
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #5DA4FF), color-stop(100%, #417BFF));
    background: -webkit-linear-gradient(#5DA4FF, #417BFF);
    background: -moz-linear-gradient(#5DA4FF, #417BFF);
    background: -o-linear-gradient(#5DA4FF, #417BFF);
    background: linear-gradient(#5DA4FF, #417BFF);
    border-radius: 30px;
  }
  .ReactModal__Overlay {
    background: rgba(0, 0, 0, 0.5) !important;
    z-index: 9;
  }
  .custom-modal .modal-dialog {
    max-width: 900px;
    margin: 0 auto;
  }
  .custom-modal .modal-content {
    border: 0px;
    border-radius: 15px;
    padding: 0;
    margin-bottom: 15px;
    /* overflow: hidden; */
  }
  .custom-modal .modal-body{
    overflow: hidden;
    border-radius: 15px;
  }
  .custom-modal .close {
    font-size: 28px;
    line-height: 20px;
    color: #fff;
    opacity: 1;
    border: none;
    background: transparent;
    font-weight: bold;
    position: absolute;
    right: 0px;
    top: -33px;
    z-index: 1;
    padding: 3px 6px;
  }
  .custom-modal .modal-header {
    padding: 5px 0 15px;
    border-bottom: none;
  }
  .custom-modal .modal-body {
    padding: 0;
  }
  .custom-modal .modal-body iframe {
    display: block;
  }
  .full-screen-modal .modal-dialog {
    max-width: 80%;
  }
  .full-screen-modal .modal-content {
    margin: 0;
  }
  .mnh-270 {
    min-height: 260px !important;
  }
.icon-default-style {
    color: #04f;
    background: #edf2fe;
    border-radius: 6px;
    display: inline-flex;
    padding: 15px;
}
.sub-heading{
    font-weight: 400;
}
.footer-address-img{
    opacity: 0.6;
}
.heading-typed-flip{
    color: #5DA4FF;
    font-size: 1.1em;
}

.hi-title {
    letter-spacing: -.03em;
    margin-top: 0;
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 1.2;
    color: #000;
}
.hi-content p {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.45;
    text-align: left;
}
.info-wrap .white-box {
    box-shadow: none;
    border: none;
}
.info-wrap .white-box img {
    box-shadow: 0px 20px 50px rgb(0 0 0 / 40%);
    border-radius: 6px;
}
.pwu-icon {
    width: 10rem;
    height: 10rem;
    border-radius: 200px;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0px 15px 30px rgb(0 0 0 / 25%);
}
.pwu-icon img {
    height: 100%;
    object-fit: cover;
}
.pwu-dark-blue {
    background-color: #041d34;
}
.pwu-purple {
    background-color: #8a78f0;
}
.pwu-green {
    background-color: #19c185;
}
.pwu-red {
    background-color: #fe565b;
}
.pwu-block .pwu-col {
    width: 25%;
}
.pwu-box .title-md  {
    font-size: 1.5em;
    position: relative;
}
.testimonial-item {
    padding: 2rem 2rem;
}
.testimonial-box {
    box-shadow: 0 0 30px #cccccc;
    position: relative;
    background: #fff;
    border-radius: 24px;
    max-width: 430px;
}
.testimonial-name {
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1.33;
    margin-bottom: 6px;
}
.testimonial-msg {
    font-size: 1em;
    line-height: 1.5;
}

.info-msg {
    position: absolute;
    bottom: 32px;
    max-width: 600px;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    z-index: 1;
}
.info-msg p {
    color: rgb(255 255 255 / 50%);
    font-size: 14px;
}
.read-more-btn {
    background: rgb(211 211 211 / 80%);
    border-radius: 15px;
    border: none;
    font-size: 12px;
    padding: 2px 15px;
    color: #fff;
}
.read-more-modal {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    padding: 40px;
    background: #000;
    height: 70%;
    bottom: -100%;
    transition: all 0.5s ease-in-out 0s;
}
.read-more-modal.open {
    display: block;
    bottom: 0%;
}
.read-more-modal p {
    font-size: 17px;
    color: #fff;
}
.readmore-modal-body {
    max-width: 850px;
    margin: 0 auto;
}
.rm-close-icon {
    position: absolute;
    right: 25px;
    top: 25px;
    background: rgb(255 255 255 / 10%);
    border-radius: 5px;
    padding: 7px 11px 12px 11px;
    font-size: 21px;
    color: #fff;
    line-height: 14px;
    border: none;
    vertical-align: -7px;
}

.main-banner-wrap .title-lg br {
    display: none;
}
.fw-100 {
    font-weight: 100 !important;
}
.loading-wrap {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(90deg,#100727 0%,rgba(16,7,39,.50) 100%);
}
.loading-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.loading-icon img {
    max-width: 350px;
}
.book-technician-modal .modal-dialog {
    max-width: 80%;
    min-height: 100%;
}
.book-technician-modal .modal-content {
    margin-bottom: 0;
}
.captcha-modal .modal-dialog {
    max-width: 300px;
    min-height: 100%;
}
.aws-captcha-body {
    height: 460px;
    padding: 30px 0;
}
#my-captcha-container-banner , #my-captcha-container-header , #my-captcha-container-footer{
    margin: 0 auto;
}
.error-slot-book{
    color: #fff;
    text-align: center;
}
.spinner-pos {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    width: 100%;
}
.error-icon {
    text-align: center;
}
.error-icon img {
    max-width: 750px;
}
.error-block {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
}
.error-block .theme-btn {
    text-decoration: none;
}
.title-captcha{
    font-size: 15px !important;
}
.error-block .hi-title {
    position: absolute;
    left: 0;
    width: 100%;
    top: 17%;
    font-size: 4rem;
    letter-spacing: 1.5px;
}
.tf-v1-popup .tf-v1-close{
    color: #fff !important;
    opacity: 1; 
}
.city-heading{
    font-size: 15px !important;
}